@use '../../scss/' as *;

.tf-section.how-we-work {
  padding: 80px 0 52px;
}

.tf-title .title {
  @include desktop-width {
    font-size: 30px;
  }
}

.tf-title .sub-title {
  @include desktop-width {
    font-size: 18px;
  }
}
